import { render, staticRenderFns } from "./webpurchasedetailgeral.vue?vue&type=template&id=28b64935&"
import script from "./webpurchasedetailgeral.vue?vue&type=script&lang=js&"
export * from "./webpurchasedetailgeral.vue?vue&type=script&lang=js&"
import style0 from "./webpurchasedetailgeral.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "webpurchasedetailgeral.vue"
export default component.exports